'use client';
import Footer from '@/components/shared/Footer';
import Header from '@/components/shared/Header';
import Image from 'next/image';
import ReadyForSuccess from '@/components/HomeComponents/ReadyForSuccess';
import FrequentlyQuestions from '@/components/HomeComponents/FrequentlyQuestions';
import AdvantagesPlatform from '@/components/HomeComponents/AdvantagesPlatform';
import AcademyInstructors from '@/components/HomeComponents/AcademyInstructors';
import PopularBooks from '@/components/HomeComponents/PopularBooks';
import LatestExams from '@/components/HomeComponents/LatestExams';
import MyCourses from '@/components/HomeComponents/MyCourses';
import LatestCourses from '@/components/HomeComponents/LatestCourses';
import Subjects from '@/components/HomeComponents/Subjects';
import HeroHome from '@/components/HomeComponents/HeroHome';
import start from '@/public/images/start.png';
import heroBg from '@/public/images/hero-bg.png';
import SocialMedia from '@/components/shared/socialMedia/SocialMedia';
import SearchSection from '@/components/HomeComponents/SearchSection/SearchSection';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { useEffect, useState } from 'react';
import USER, { SelectHome } from '@/redux/User';
import LandingPage from './waiting/page';

export default function Home() {
	const dispatch = useAppDispatch();
	const home = useAppSelector(SelectHome);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(USER.thunks.doGetHome({}));
			setLoading(false);
		};
		fetchData();
	}, []);

	return (
		<div>
			<div
				style={{
					backgroundImage: `url(${heroBg.src})`,
					width: '100%',
					height: '100%',
					backgroundSize: 'cover',
					backgroundPosition: 'top center',
					paddingBottom: '4rem',
				}}>
				<Header />
				<HeroHome />
				<SearchSection />
				{
					<AcademyInstructors
						loading={loading}
						teachers={home?.teachers}
						full={false}
					/>
				}
			</div>
			{/* <div className=" flex flex-col gap-5 items-center justify-center">
        <p className="titleHeading" data-aos="fade-up">
          أفضل مدرسين مصر في منصة واحدة !
        </p>
        <p
          className=" text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10"
          // data-aos="fade-up"
        >
          يمكنك التسجيل الآن علي المنصة مجانًا والإشتراك مع نخبة من المدرسين
          المتواجدين لدينا .
        </p>
        <Image
          src={start}
          alt="star"
          className="w-full max-w-[90%] sm:max-w-[350px] max-h-[350px] md:max-w-[500px] md:max-h-[500px]"
          priority
          // data-aos="fade-up"
        />
      </div> */}
			{/* bg-[url('/images/lines.svg')] */}
			{/* <SuccessPartner/> */}

			<Subjects loading={loading} subjects={home?.subjects} />
			{/* <MyCourses loading={loading} courses={home?.bought_courses} /> */}
			<AcademyInstructors loading={loading} teachers={home?.teachers} />
			<LatestCourses loading={loading} courses={home?.courses} />
			<LatestExams loading={loading} fullExams={home?.full_exams} />
			{/* <LatestTests/> */}
			<PopularBooks loading={loading} books={home?.books} />

			<AdvantagesPlatform />
			{/* <AchieveYourGoals /> */}
			{/* <StudentsOpinion /> */}
			<FrequentlyQuestions />
			<ReadyForSuccess />
			<Footer />
			<SocialMedia />
		</div>
	);
}
