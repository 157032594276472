'use client';
import MyLink from '@/components/shared/myLink/MyLink';
import Link from 'next/link';
import React from 'react';

const index = () => {
	return (
		<div className='container'>
			<div className='bg-primaryColor h-[321px] 2xl:h-[489px] rounded-[36px] flex flex-col items-center justify-center gap-10 2xl:gap-5 my-20 '>
				<p className=' text-45 text-white font-[700]'>جاهز لطريق التفوق؟</p>
				<p className=' text-30 text-white font-[500] max-w-[900px] text-center'>
					أكبر وأقوى منصة تعليمية لطلاب الثانوية العامة في مصر والوطن العربي.
          <br />
					X-Hub منصة إستعد لتجربة تعليمية متميزة مع خبراء التدريس، تلبّي
					إحتياجاتك وتساعدك على التفوق في دراستك وأكثر.
				</p>
				<MyLink
					href={'/signup'}
					className='text-16 text-black font-[600] bg-white shadow-[0px_4px_134px_0px_#FFF] rounded-[10px] py-3 px-20'>
					انشئ حساب الان
				</MyLink>
			</div>
		</div>
	);
};

export default index;
