'use client';
import React from 'react';
import starB from '@/public/images/starB.svg';
import Image from 'next/image';

const Card = ({
	title,
	description,
}: {
	title: string;
	description: string;
}) => {
	return (
		<div className=' bg-[#252F39] rounded-[15px] py-[60px] px-[20px] flex flex-col items-center justify-center gap-6'>
			<div className=' bg-primaryColor border-[3px] border-[#38B2F04D] w-[55px] h-[55px] flex items-center justify-center rounded-full'>
				<Image src={starB} alt='starB' />
			</div>
			<p className='text-24 font-bold text-white'>{title}</p>
			<p className='text-16 text-textColor font-[300] max-w-[410px] text-center'>
				{description}
			</p>
		</div>
	);
};

const index = () => {
	return (
		<div id='advantages' className='container my-20 '>
			<p className='titleHeading'>خريطة طريقك للتفوق تبدأ معنا</p>
			<p className=' text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10'>
				نرسم لك طريق التفوق من البداية، لتيسير عليك الدراسة وتقديم كافة وسائل
				الدعم المستمر، لضمان نجاحك وتفوقك الدائم.
			</p>
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
				<Card
					title='محاضرات مجانية'
					description='استفد من محاضرات مجانية في بعض المواد، المتاحة لجميع الطلاب المشتركين.'
				/>
				<Card
					title='مراجعات ومتابعة دورية'
					description='نوفر مراجعات شاملة بعد كل فصل، بالإضافة إلى متابعة أداء أسبوعية مخصصة.'
				/>
				<Card
					title='بنك أسئلة ضخم'
					description='احصل على أفضل التدريبات عبر بنك أسئلة يضم آلاف الأسئلة المتوقعة من مصادر متنوعة.'
				/>
				<Card
					title='دعم فني مستمر'
					description='تواصل مع فريق الدعم الفني على مدار الساعة لحل مشاكلك والإجابة على استفساراتك.'
				/>
				<Card
					title='اختبارات وتدريبات'
					description='استعد جيداً للاختبارات النهائية من خلال امتحانات دورية وتدريبات مكثفة.'
				/>
				<Card
					title='مسابقات وجوائز'
					description='شارك في مسابقات تعليمية دورية واربح جوائز مالية مخصصة للمتفوقين.'
				/>
			</div>
		</div>
	);
};

export default index;
