"use client";
import React, { useEffect, useState } from "react";
import bookImage from "@/public/images/bookImage.svg";
import exam from "@/public/images/exam.svg";
import bookmark from "@/public/images/bookmark.svg";
import Image from "next/image";
import file from "@/public/images/file.svg";
import star from "@/public/images/star.svg";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Link from "next/link";
import { SelectLoggedUser } from "@/redux/Auth";
import { getCookie } from "cookies-next";
import defaultImage from "@/public/images/default_image.jpg";
import MyLink from "../myLink/MyLink";

const index = ({
  id,
  image,
  years,
  teacherName,
  teacherImage,
  subject,
  price,
  name,
  examNumber,
  starNumber,
  fileNumber,
}: {
  id: number;
  image: string;
  years: any;
  teacherName: string;
  teacherImage: string;
  subject: string;
  price: string;
  name: string;
  description?: string;
  examNumber: string;
  starNumber: string;
  fileNumber: string;
}) => {
  const dispatch = useAppDispatch();
  const [hidden, setHidden] = useState(false);

  const loggedUser = useAppSelector(SelectLoggedUser);

  useEffect(() => {
    const token = getCookie("Education_User_token");
    if (!token && !loggedUser.isUserLogged && !loggedUser.userToken) {
      setHidden(true);
    }
  }, []);

  return (
    <MyLink
      href={`/books/${id}`}
      className="flex flex-col gap-5 h-full"
    >
      <div
        data-aos="fade-up"
        className="p-5 bg-[#252F39] rounded-[15px] w-fit border border-[#56566B] flex flex-col gap-5 justify-between h-full"
      >
        <Image
          src={image || bookImage}
          alt="courseImage"
          className="object-cover mx-auto h-[220px] rounded-[5px]"
          height={220}
          width={360}
        />
        <div className=" flex items-center gap-3 flex-wrap">
          {years?.map((item: any, index: number) => (
            <div
              key={index}
              className=" flex items-center gap-3"
            >
              <Image
                src={bookmark}
                alt="bookmark"
              />
              <p className=" text-18 font-[600] text-primaryColor">
                {item.name}
              </p>
            </div>
          ))}
        </div>

        <div className=" flex items-center justify-between">
          <div className=" flex items-center gap-3">
            <Image
              src={teacherImage || defaultImage}
              alt={teacherName}
              className="rounded-[10px]"
              width={64}
              height={64}
            />
            <div>
              <p className=" text-18 text-white font-[500]">{teacherName}</p>
              <p className=" text-16 text-textColor ">{subject}</p>
            </div>
          </div>
          <p className=" text-14 text-primaryColor font-[600] text-center py-[5px] px-2 rounded-full border border-primaryColor">
            {price} جنية
          </p>
        </div>
        <p className=" text-[20px] text-white font-[600] max-w-[370px]">
          {name}
        </p>
        <div className=" bg-[#2F3943] rounded-[10px] flex items-center justify-between py-3 px-3 md:px-3">
          <div className=" flex flex-col items-center justify-center grow">
            <Image
              src={exam}
              alt="time"
            />
            <p className=" text-16 text-textColor font-[500]">
              {parseInt(examNumber) > 100 ? "+100" : examNumber} متوفره
            </p>
          </div>
          <div className=" flex flex-col items-center justify-center grow border-x border-[#6F7275]  ">
            <Image
              src={star}
              alt="star"
            />
            <p className=" text-16 text-textColor font-[500]">
              {starNumber} نجوم
            </p>
          </div>
          <div className=" flex flex-col items-center justify-center grow ">
            <Image
              src={file}
              alt="file"
            />
            <p className=" text-16 text-textColor font-[500]">
              {fileNumber} صفحة
            </p>
          </div>
        </div>
        <button>
          <p className=" w-full bg-primaryColor rounded-[10px] text-16 text-white font-[500] py-3">
            شراء الكتاب
          </p>
        </button>
      </div>
    </MyLink>
  );
};

export default index;
